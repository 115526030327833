#modal {
    width: 100%;
    height: 100%;
}

.modal {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 5% 5% 0 5%;
    z-index: 199;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;
    opacity: 0;
    transition: all 0.4s ease-in;

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 8px 32px 0 var(--clr-primary-translucent);
}

.modal.modal-show {
    opacity: 1;
    pointer-events: all;
}

.modal > * {
    transform: translate(0, 100%);
}

.modal.modal-show > * {
    transform: translate(0, 0);
}

.modal-backdrop {
    height: 100%;
    width: 100%;
    position: absolute;
}

.project-large {
    width: 65%;
    height: 100%;
    /* border-radius: 7.5px; */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--clr-dark-extra);
    box-shadow: 0 -5px 20px var(--clr-primary-translucent);
    padding: 2.5%;
    overflow: auto;
}

.pl-head {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2.5%;
    border-bottom: 1px solid var(--clr-light-translucent);
    position: relative;
}

.pl-title {
    font-size: 2rem;
    font-family: var(--font-secondary);
    font-weight: 700;
    color: var(--clr-accent);
    margin-bottom: 1%;
}

.pl-slug {
    color: var(--clr-light);
    font-size: 1rem;
    max-width: 50ch;
    line-height: 1.4;
}

.pl-logo-container {
    height: 6.5rem;
    width: 6.5rem;
    display: grid;
    place-items: center;
    background-color: var(--clr-dark);
    /* border-radius: 15px; */
}

.light .pl-logo-container {
    background-color: var(--clr-light-translucent);
}

.pl-logo {
    max-width: 85%;
    max-height: 85%;
}

.pl-cancel {
    font-size: 1.5rem;
    height: 2.25rem;
    width: 2.25rem;
    display: grid;
    place-items: center;
    background-color: var(--clr-dark);
    /* border-radius: 50%; */
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.light .pl-cancel {
    background-color: var(--clr-light-super-translucent);
}

.pl-cancel i {
    color: var(--clr-light);
}

.pl-cancel:hover i {
    color: var(--clr-accent);
}

.pl-tech-stack {
    padding: 1.25% 0;
    display: flex;
    flex-direction: column;
}

.pl-ts-heading {
    color: var(--clr-light);
    font-size: 1.15rem;
}

.pl-details {
    display: flex;
    flex-direction: column;
    /* height: 90%; */
}

.pl-ts,
.pl-links {
    padding-top: 1.5%;
    display: flex;
    gap: 2rem;
}

.pl-demos {
    display: flex;
    align-items: center;
    overflow-x: auto;
    height: 27.5rem;
    cursor: grab;
    padding-bottom: 2.5%;
}

.pl-demos::-webkit-scrollbar {
    display: none;
}

.pl-demo {
    background-color: var(--clr-dark);
    flex: 0 0 auto;
    height: 100%;
    /* border-radius: 7.5px; */
    padding: 0.25rem;
    margin-right: 2.5%;
    overflow: hidden;
}

.light .pl-demo {
    background-color: var(--clr-light-translucent);
}

.pl-demo-media {
    max-width: 100%;
    height: 100%;
}

.pl-desription {
    margin-bottom: 5rem;
}

.pl-d-heading {
    color: var(--clr-accent);
    font-size: 1.25rem;
    margin-bottom: 1%;
}

@media screen and (max-width: 900px) {
    .modal {
        padding: 5% 2% 0 2%;
    }

    .project-large {
        width: 100%;
        padding: 5%;
    }

    .pl-head {
        gap: 1rem;
    }

    .pl-title {
        font-size: 1.75rem;
        max-width: 15ch;
    }

    .pl-slug {
        font-size: 0.85rem;
    }

    .pl-logo-container {
        height: 7rem;
        width: 7rem;
        flex: 0 0 7rem;
        display: grid;
        place-items: center;
        padding: 0.25rem;
    }

    .pl-cancel {
        height: 2rem;
        width: 2rem;
    }

    .pl-details {
        justify-content: space-evenly;
        /* height: 75%; */
    }

    .pl-tech-stack {
        padding: 2.5% 0;
    }

    .pl-ts {
        flex-wrap: wrap;
        gap: 1rem;
    }

    .pl-demos {
        height: 20rem;
    }

    .pl-demo {
        flex: 0 0 27.5rem;
    }

    .pl-d {
        font-size: 1.25rem;
    }
}
