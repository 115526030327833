.App {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

section {
    margin-bottom: 2rem;
    width: 100%;
    padding: 0 17.5% 0 27.5%;
    margin: 5% 0;
}

section:first-of-type {
    margin: 0;
}

@media screen and (max-width: 1250px) {
    section {
        padding: 0 5%;
        margin: 7.5% 0;
    }
}
