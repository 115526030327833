#socials {
    position: fixed;
    bottom: 0;
    right: 5%;
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    align-items: center;
}

.line {
    height: 7.5rem;
    border-left: 0.1rem dashed var(--clr-primary);
}

.social {
    border: none;
    background: none;
    cursor: pointer;
}

.social i {
    font-size: 1.5rem;
    color: var(--clr-light);
}

.theme-toggle {
    width: 1.75rem;
    height: 1.75rem;
}

.theme-toggle:hover * {
    fill: var(--clr-primary);
}

.theme-toggle .toggle-sun {
    transform-origin: center center;
    transform: rotate(0);
}

.light .theme-toggle .toggle-sun {
    transform: rotate(900deg);
}

.theme-toggle .outer {
    transform: rotate(0deg) matrix(1, 0, 0, 1, 12, 12);
}

.light .theme-toggle .outer {
    transform: rotate(900deg) matrix(1, 0, 0, 1, 12, 12);
}

.social:hover i {
    color: var(--clr-accent);
}

.social:hover:nth-child(2n) i {
    color: var(--clr-primary);
}

@media screen and (max-width: 900px) {
    #socials {
        position: static;
        flex-direction: row;
        justify-content: center;
    }

    .line {
        display: none;
    }
}
