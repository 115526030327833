#skills {
}

.skills {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.4s;
    margin: 3.5%;
}

.skill:hover {
    transform: scale(1.2);
}

.skill-img {
    width: 6.5rem;
    height: 6.5rem;
    font-size: 6.5rem;
}

@media screen and (max-width: 1000px) {
    .skill {
        margin: 5% 7.5%;
    }

    .skill-img {
        width: 5rem;
        height: 5rem;
        font-size: 5rem;
    }
}

.skill-img path {
    fill: var(--clr-light);
}

.skill-name {
    color: var(--clr-primary);
    font-size: 1.5rem;
    margin-top: 5%;
}

.skill:nth-child(2n) .skill-name {
    color: var(--clr-accent);
}

.skill-learning {
    font-weight: 600;
    color: var(--clr-primary);
}
