.experiences {
    height: 22.5rem;
    padding: 0 5%;
    padding-top: 2.5%;
    display: flex;
    align-items: center;
}

.e-nav {
    width: 40%;
    position: relative;
    list-style: none;
    border-left: 1.25px solid var(--clr-light-translucent);
    --tab-height: 3.5rem;
}

.e-nav-item {
    width: 100%;
}

.e-nav-button {
    background-color: transparent;
    border: none;
    font-size: 1.05rem;
    font-family: var(--font-secondary);
    font-weight: bold;
    text-align: left;
    width: 85%;
    height: var(--tab-height);
    padding: 1.5% 5%;
    cursor: pointer;
}

.e-nav-button:hover {
    background-color: var(--clr-light-super-translucent);
    color: var(--clr-accent);
}

.e-nav-button.active {
    color: var(--clr-accent);
}

.track {
    position: absolute;
    inset: 0;
    height: var(--tab-height);
    width: 0.25rem;
    background-color: var(--clr-accent);
}

.experiences-container {
    width: 60%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    scroll-behavior: auto;
}

.experience {
    flex: 0 0 100%;
    flex-direction: column;
    display: none;
}

.experience.active {
    display: flex;
    animation: e-enter 1s forwards linear;
}

@keyframes e-enter {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.e-heading {
    font-size: 1.25rem;
}

.e-heading-part {
    color: var(--clr-accent);
}

.e-duration {
    padding-top: 5%;
    font-size: 0.9rem;
}

.e-points {
    padding-top: 5%;
    list-style: none;
}

.e-point {
    padding: 1% 0 1% 2.5%;
    position: relative;
    font-size: 1rem;
    max-width: 85%;
}

.e-point::before {
    content: '> ';
    position: absolute;
    transform: translate(-200%, -12.5%);
    font-family: var(--font-secondary);
    font-size: 1.25rem;
    color: var(--clr-primary);
}

@media screen and (max-width: 1000px) {
    .experiences {
        height: 32.5rem;
        padding: 2.5%;
        flex-direction: column;
    }

    .e-nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-bottom: 1.25px solid
            var(--clr-light-translucent);
    }

    .e-nav-item {
        width: var(--tab-width);
        min-width: 25%;
    }

    .e-nav-button {
        font-size: 0.9rem;
        text-align: center;
        width: 100%;
    }

    .track {
        top: 100%;
        bottom: 0;
        height: 0.25rem;
        width: var(--tab-width);
    }

    .experiences-container {
        padding-top: 5%;
        width: 100%;
    }

    .experience {
        align-items: center;
        text-align: center;
    }

    .e-point {
        text-align: left;
        font-size: 1rem;
        padding-left: 5%;
        max-width: 100%;
    }
}
