@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        'Courier New', monospace;
}

:root {
    /* Colors */

    --clr-primary: hsl(229, 83%, 60%);
    --clr-primary-light: hsl(229, 89%, 71%);
    --clr-primary-translucent: hsla(229, 83%, 60%, 0.4);
    --clr-secondary: hsl(275, 80%, 59%);
    --clr-secondary-translucent: hsla(275, 80%, 59%, 0.4);

    --clr-dark: hsl(218, 23%, 21%);
    --clr-dark-translucent: hsla(218, 23%, 21%, 0.4);

    --clr-dark-extra: hsl(195, 6%, 6%);
    --clr-dark-extra-translucent: hsla(195, 6%, 6%, 0.95);

    --clr-light: hsl(197, 24%, 94%);
    --clr-light-translucent: rgba(236, 241, 243, 0.3);
    --clr-light-super-translucent: rgba(255, 255, 255, 0.1);

    --clr-accent: hsl(359, 77%, 56%);
    --clr-accent-translucent: hsla(337, 35%, 43%, 0.4);

    /* Fonts */
    --font-primary: 'Montserrat', sans-serif;
    --font-secondary: 'Karla', sans-serif;
}

:root.light {
    --clr-light-translucent: hsl(218, 23%, 21%);
    --clr-light-super-translucent: hsla(218, 23%, 21%, 0.4);

    --clr-light: hsl(180, 3%, 7%);

    --clr-dark-extra: hsl(0, 0%, 100%);
    --clr-dark: rgba(208, 214, 216, 0.514);
    --clr-dark-translucent: rgba(255, 255, 255, 0.1);
    --clr-dark-extra-translucent: rgba(255, 255, 255, 0.8);

    /* filter: invert(1) hue-rotate(180deg); */
}

/* .light img,
.light video {
    filter: invert(1) hue-rotate(180deg);
} */

@media screen and (max-width: 800px) {
    :root {
        font-size: 14px;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-primary);
    font-weight: 400;
    color: var(--clr-light);
    transition: all 0.3s;
    scroll-behavior: smooth;

    scrollbar-color: var(--clr-light-translucent)
        transparent;
    scrollbar-width: thin;
}

*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--clr-light-translucent);
    border-radius: 100rem;
    transition: all 0.25s;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--clr-primary-translucent);
}

html,
body,
#root {
    /* height: 100%; */
    background-color: var(--clr-dark-extra);
}

input,
button,
textarea input:focus,
button:focus,
textarea:focus {
    border: none;
    outline: none;
}

.about-p {
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.5;
    max-width: 70ch;
}

a {
    text-decoration: none;
}

.link {
    color: var(--clr-primary);
    position: relative;
}

.link:hover {
    color: var(--clr-accent);
}

.link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--clr-primary);
    visibility: hidden;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.3s ease-in-out;
}

.link:hover::after {
    visibility: visible;
    transform: scaleX(1);
}

.btn {
    /* resets */
    background: none;
    border: none;

    border: 1px solid var(--clr-primary-translucent);
    background-color: var(--clr-primary-translucent);
    color: var(--clr-light);
    position: relative;
}

.light .btn,
.light .send-icon {
    background-color: var(--clr-primary);
    color: var(--clr-dark-extra);
}

.btn::after,
.btn::before {
    position: absolute;
    content: '';
    width: 0%;
    height: 0%;
    visibility: hidden;
}

.btn::after {
    bottom: -3px;
    right: -3px;
    border-left: 3px solid var(--clr-light);
    border-bottom: 3px solid var(--clr-light);
    transition: width 0.1s ease 0.1s, height 0.1s ease,
        visibility 0s 0.2s;
}

.btn::before {
    top: -3px;
    left: -3px;
    border-top: 3px solid var(--clr-light);
    border-right: 3px solid var(--clr-light);
    transition: width 0.1s ease 0.3s, height 0.1s ease 0.2s,
        visibility 0s 0.4s;
}

.btn:hover {
    animation: pulse 1s ease-out 0.4s;
}

.btn:hover::after,
.btn:hover::before {
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    visibility: visible;
    transition: width 0.1s ease 0.2s, height 0.1s ease 0.3s,
        visibility 0s 0.2s;
}

.btn:hover::after {
    transition: width 0.1s ease 0.2s, height 0.1s ease 0.3s,
        visibility 0s 0.2s;
}

.btn:hover::before {
    transition: width 0.1s ease, height 0.1s ease 0.1s;
}
