#home {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.description {
    line-height: 1.45;
    font-weight: 700;
}

.greet {
    font-size: 2.5rem;
    display: flex;
    align-items: center;
}

.greet-anim {
    animation: greet-anim 0.3s infinite alternate ease-in;
    transform-origin: bottom right;
}

@keyframes greet-anim {
    from {
        transform: rotate(30deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.name {
    font-size: 4rem;
    font-family: var(--font-secondary);
}

.name-span {
    color: var(--clr-accent);
}

.sub-line {
    font-size: 3rem;
    color: var(--clr-primary);
}

.description-text {
    font-size: 1rem;
    opacity: 0.85;
    max-width: 55ch;
    line-height: 1.3;
}

.hero-image img {
    width: 18.5rem;
    height: 18.5rem;
}

@media screen and (max-width: 1500px) {
    .name {
        font-size: 2.75rem;
    }

    .sub-line {
        font-size: 2.25rem;
    }

    .greet {
        font-size: 1.5rem;
    }

    .hero-image img {
        width: 18.5rem;
        height: 18.5rem;
    }
}

@media screen and (max-width: 800px) {
    #home {
        flex-direction: column-reverse;
    }

    .description {
        height: 35%;
        text-align: center;
    }

    .hero-image {
        height: 65%;
    }

    .name {
        font-size: 2.75rem;
    }

    .sub-line {
        font-size: 2.25rem;
    }

    .greet {
        font-size: 2rem;
        justify-content: center;
    }
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: hero-image 200ms linear forwards 500ms;
    transform: scale(0);
}

@keyframes hero-image {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.hero-img-container {
    border-radius: 50%;
    border: solid 1px var(--clr-dark);
    overflow: hidden;
}

.light .hero-img-container {
    border-color: var(--clr-light);
}

.go-down {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 5rem;
    color: var(--clr-light);
}

@media screen and (max-width: 1000px) {
    .go-down {
        bottom: 0;
    }
}

.go-down:first-of-type {
    cursor: pointer;
}

.go-down:nth-of-type(2) {
    opacity: 0.5;
    animation: gd-anim 1s forwards infinite ease-in;
}

.go-down:nth-of-type(3) {
    opacity: 0.5;
    animation: gd-anim 1s 0.5s forwards infinite ease-in;
}

@keyframes gd-anim {
    from {
        transform: translate(-50%, 0);
        opacity: 1;
    }
    to {
        transform: translate(-50%, -65%);
        opacity: 0;
    }
}
