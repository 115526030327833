#footer {
    height: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.footer-text {
    font-size: 1rem;
    color: var(--clr-light);
    font-family: var(--font-secondary);
}

.footer-accent {
    vertical-align: middle;
    color: var(--clr-accent);
    font-weight: 700;
    font-family: var(--font-primary);
}

.footer-name::after {
    content: '>';
}

.footer-name::before {
    content: '<';
}
