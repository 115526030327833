.heading {
    font-size: 3rem;
    font-family: var(--font-secondary);
    text-align: start;
    width: 100%;
    height: auto;
    padding-bottom: 2.5%;
    margin-top: 2.5%;
}

.heading-part:not(:last-child)::after {
    content: ' ';
}

.heading-index {
    font-size: 1.5rem;
    color: var(--clr-accent);
}

.heading-index::after {
    content: ' ';
}

@media screen and (max-width: 1000px) {
    .heading {
        font-size: 2.5rem;
    }
}
