#navbar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 2.5% 1.5%;
    padding-top: 0;
    z-index: 20;
    --nav-item-width: 10rem;
}

.nav-logo {
    border: solid 1px var(--clr-accent);
    color: var(--clr-accent);
    padding: 1rem;
    display: none;
}

.nav-item {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: var(--nav-item-width);
    height: 5rem;
    border-left: 2px dashed var(--clr-light-translucent);
    border-bottom: 2px dashed var(--clr-light-translucent);
}

.nav-item:hover {
    border-bottom-color: var(--clr-primary);
}

nav:hover > .nav-item:not(:hover) {
    opacity: 0.75;
}

.nav-link {
    font-size: 1.1rem;
    font-family: var(--font-secondary);
}

.nav-item:hover .nav-link {
    color: var(--clr-primary);
}

.nav-index {
    font-size: 1rem;
    font-weight: 700;
    color: var(--clr-primary-light);
}

.nav-item.active {
    border-color: var(--clr-accent);
}

.nav-item.next-active {
    border-left-color: var(--clr-accent);
}

.nav-item.active .nav-link {
    color: var(--clr-accent);
    font-weight: 700;
}

/* Start Animation */
/* .nav-item:nth-of-type(1) {
    animation: home-start 150ms forwards ease-in;
}

.nav-item:nth-of-type(2) {
    animation: about-start 300ms forwards ease-in;
}

.nav-item:nth-of-type(3) {
    animation: skills-start 450ms forwards ease-in;
}

.nav-item:nth-of-type(4) {
    animation: projects-start 600ms forwards ease-in;
}

.nav-item:nth-of-type(5) {
    animation: experiences-start 750ms forwards ease-in;
}

.nav-item:nth-of-type(6) {
    animation: contact-me-start 900ms forwards ease-in;
} */

@keyframes home-start {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes about-start {
    from {
        transform: translateY(-200%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes skills-start {
    from {
        transform: translateY(-300%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes projects-start {
    from {
        transform: translateY(-400%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes experiences-start {
    from {
        transform: translateY(-500%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes contact-me-start {
    from {
        transform: translateY(-500%);
    }
    to {
        transform: translateY(0);
    }
}

/* Resume BTN */
.nav-btn {
    display: block;
    cursor: pointer;
    width: var(--nav-item-width);
    margin: 1.5rem 0;
    padding: 1rem 2rem;
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 1.1rem;
}

@media screen and (max-width: 900px) {
    #navbar {
        position: fixed;
        width: 100%;
        padding: 0.5% 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: var(--clr-dark-extra-translucent);
        backdrop-filter: blur(2.5px);
        -webkit-backdrop-filter: blur(2.5px);
        box-shadow: 0 2px 7px 0 var(--clr-light-translucent);
    }

    .nav-logo {
        display: block;
    }

    .nav-item {
        display: none;
    }
}
