.pill {
    border: 1px solid var(--clr-primary);
    /* border-radius: 7.5px; */
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
}

.pill:hover {
    transform: scale(1.05);
}

a.pill:hover {
    background-color: var(--clr-primary);
}

.pill .project-icon {
    height: 1rem;
    width: 1rem;
    color: var(--clr-light);
}

.pill-text {
    color: var(--clr-light);
}
