.contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    width: 100%;
}

.contact-field {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.contact-label {
    font-family: var(--font-secondary);
    font-size: 1.1rem;
}

.contact-input {
    border: 1.75px solid var(--clr-light-translucent);
    width: 85%;
    padding: 1.5% 2%;
    background-color: var(--clr-dark-translucent);
    color: var(--clr-light);
    font-size: 1.1rem;
}

.contact-input:-webkit-autofill,
.contact-input:-webkit-autofill:hover,
.contact-input:-webkit-autofill:focus,
.contact-input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--clr-light) !important;
}

.contact-input:focus {
    border: 1.75px solid var(--clr-primary);
}

.contact-textarea {
    resize: none;
}

.contact-btn {
    width: 25%;
    padding: 1rem 2rem;
    font-family: var(--font-secondary);
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
    pointer-events: all;
}

.send-icon {
    padding-left: 0.5rem;
}

.contact-btn.loading {
    pointer-events: none;
}

.contact-btn.loading .loading-icon {
    animation: spinner 0.5s infinite linear;
}

@keyframes spinner {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 900px) {
    .contact-input {
        width: 100%;
        padding: 3.5% 2.5%;
    }

    .contact-btn {
        width: 100%;
    }
}
