.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;

    background-color: var(--clr-dark-extra);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: none; */
    opacity: 0;
    transition: opacity .5s ease, z-index .75s ease;
}

.preloader.show {
    z-index: 100;
    opacity: 1;
    /* display: flex; */
}

.preloader__box {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.preloader__box span {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border: 2px solid var(--clr-light);
    pointer-events: none;
    animation: preloader 1.5s linear infinite;
}

.preloader__box span:nth-of-type(1) {
    border-radius: 48% 52% 68% 32% / 46% 40% 60% 54%;
    box-shadow: 0px 0px 10px 0px var(--clr-accent),
        inset 0px 0px 10px 0px var(--clr-accent);
}

.preloader__box span:nth-of-type(2) {
    animation-direction: reverse;
    border-radius: 27% 73% 64% 36% / 69% 39% 61% 31%;
    box-shadow: 0px 0px 10px 0px var(--clr-secondary),
        inset 0px 0px 10px 0px var(--clr-secondary);
}

.preloader__box span:nth-of-type(3) {
    animation-duration: 3s;
    border-radius: 58% 42% 30% 70% / 38% 59% 41% 62%;
    box-shadow: 0px 0px 10px 0px var(--clr-primary),
        inset 0px 0px 10px 0px var(--clr-primary);
}

@keyframes preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
