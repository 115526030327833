.project {
    height: 22.5rem;
    width: 28.5rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-color: var(--clr-dark);
    /* border-radius: 5px; */
    cursor: pointer;
    /* box-shadow: 0 0 10px var(--clr-light); */
}

.project:hover {
    transform: scale(1.05);
}

.project-cover-container {
    height: 15.5rem;
    position: relative;
    display: grid;
    place-items: center;
}

.project-cover {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;
    /* filter: hue-rotate(90deg); */
}

.project:hover .project-cover {
    filter: hue-rotate(360deg);
}

/* .project-cover-container::before {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
    background-color: var(--clr-accent);
    clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 100%);
    transition: clip-path 400ms;
}

.project-cover-container::after {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
    background-color: var(--clr-primary);
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0 100%);
    transition: clip-path 400ms;
}

.project:hover .project-cover-container::before {
    clip-path: polygon(0% 0, 0% 0%, 0% 100%, 0 100%);
}

.project:hover .project-cover-container::after {
    clip-path: polygon(
        0% 100%,
        100% 100%,
        100% 0%,
        100% 100%
    );
} */

.project-description {
    height: 6rem;
    padding: 0rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-title {
    font-family: var(--font-secondary);
    font-size: 1.25rem;
    color: var(--clr-accent);
    display: flex;
    justify-content: space-between;
}

.view-button {
    color: var(--clr-light);
    position: absolute;
    top: 2.5%;
    right: 2.5%;
    text-shadow: -5px 5px 10px var(--clr-dark-extra);
}

.project-slug {
    font-size: 1rem;
}
